import type { TechRadarApi, TechRadarLoaderResponse } from "@backstage-community/plugin-tech-radar";

export class TechRadarFetcher implements TechRadarApi {
  async load(_id: string | undefined): Promise<TechRadarLoaderResponse> {
    // TODO: `fetch` bonprix tech radar data from somewhere

    return {
      entries: [
        {
          id: "typescript",
          title: "TypeScript",
          description: "TypeScript is JavaScript with syntax for types",
          key: "typescript",
          url: "https://www.typescriptlang.org/",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('TL0 date Str'), description: "NONE" }]
        },
        {
          id: "graphql",
          title: "GraphQL",
          description: "A query language for your API",
          key: "graphql",
          url: "https://graphql.org/",
          quadrant: "1",
          timeline: [{ moved: 0, ringId: "trial", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "java",
          title: "Java",
          description: "Java is a widely-used programming language for coding web applications.",
          key: "java",
          url: "https://www.java.com/",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "javascript",
          title: "JavaScript",
          description: "JavaScript, often abbreviated as JS, is a programming language and core technology of the Web, alongside HTML and CSS",
          key: "",
          url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "kotlin",
          title: "Kotlin",
          description: "Kotlin is a programming language that makes coding concise, cross-platform, and fun.",
          key: "kotlin",
          url: "https://kotlinlang.org/",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "spring",
          title: "Spring",
          description: "The Spring Framework provides a comprehensive programming and configuration model for modern Java-based enterprise applications - on any kind of deployment platform.",
          key: "spring",
          url: "https://spring.io/projects/spring-framework",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "springboot",
          title: "Spring Boot",
          description: "Spring Boot makes it easy to create stand-alone, production-grade Spring based Applications that you can just run.",
          key: "springboot",
          url: "https://spring.io/projects/spring-boot",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "springcloud",
          title: "Spring Cloud",
          description: "Spring Cloud provides tools for developers to quickly build some of the common patterns in distributed systems (e.g. configuration management, service discovery, circuit breakers, intelligent routing, micro-proxy, control bus, short lived microservices and contract testing).",
          key: "springcloud",
          url: "https://spring.io/projects/spring-cloud",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "preact",
          title: "Preact",
          description: "With Preact, you create user interfaces by assembling trees of components and elements.",
          key: "preact",
          url: "https://preactjs.com",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "vaadin",
          title: "Vaadin",
          description: "With Vaadin you can craft stunning full-stack web apps at lightning speed. Scaling from hobby projects to enterprise.",
          key: "vaadin",
          url: "https://vaadin.com",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "hulk",
          title: "HULK",
          description: "HULK is a framework to build webshop service at bonprix. Originally build for Elysium.",
          key: "hulk",
          url: "https://github.com/bonprix/hulk",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "hystrix",
          title: "Hystrix",
          description: "Latency and Fault Tolerance for Distributed Systems",
          key: "hystrix",
          url: "https://github.com/Netflix/Hystrix",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "cassandra",
          title: "Cassandra",
          description: "Apache Cassandra is an open source NoSQL distributed database",
          key: "cassandra",
          url: "https://cassandra.apache.org/_/index.html",
          quadrant: "3",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "apache_flink",
          title: "Apache Flink",
          description: "Apache Flink is a framework and distributed processing engine for stateful computations over unbounded and bounded data streams.",
          key: "apache_flink",
          url: "https://flink.apache.org/",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "clean_code",
          title: "Clean Code",
          description: "Code is clean if it can be understood easily",
          key: "clean_code",
          url: "https://de.wikipedia.org/wiki/Clean_Code",
          quadrant: "1",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "open_api",
          title: "OpenAPI",
          description: "The OpenAPI Specifications provides a formal standard for describing HTTP APIs",
          key: "open_api",
          url: "https://www.openapis.org/",
          quadrant: "1",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "github",
          title: "GitHub",
          description: "Build and ship software on a single, collaborative platform",
          key: "github",
          url: "https://github.com",
          quadrant: "3",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "redis",
          title: "Redis",
          description: "Redis (REmote DIctionary Server) is an open source, in-memory, NoSQL key/value store that is used primarily as an application cache or quick-response database.",
          key: "redis",
          url: "https://redis.io/",
          quadrant: "3",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "renovate",
          title: "Renovate",
          description: "Renovate is an automated dependency update tool.",
          key: "renovate",
          url: "https://github.com/renovatebot/renovate",
          quadrant: "2",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "vite",
          title: "Vite",
          description: "Vite is a blazing fast frontend build tool powering the next generation of web applications.",
          key: "vite",
          url: "https://vite.dev/",
          quadrant: "2",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "maven",
          title: "Maven",
          description: "Apache Maven is a software project management and comprehension tool. Based on the concept of a project object model (POM), Maven can manage a project's build, reporting and documentation from a central piece of information.",
          key: "maven",
          url: "https://maven.apache.org/",
          quadrant: "2",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "handlebars",
          title: "Handlebars",
          description: "Handlebars provides the power necessary to let you build semantic templates effectively with no frustration.",
          key: "handlebars",
          url: "https://handlebarsjs.com/",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "sonarqube",
          title: "Sonarqube",
          description: "SonarQube is a self-managed, automatic code review tool that systematically helps you deliver Clean Code",
          key: "sonarqube",
          url: "https://www.sonarsource.com/products/sonarqube/",
          quadrant: "2",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "aws_fargate",
          title: "AWS Fargate",
          description: "AWS Fargate is a serverless, pay-as-you-go compute engine that lets you focus on building applications without managing servers.",
          key: "aws_fargate",
          url: "https://aws.amazon.com/fargate/?nc1=h_ls",
          quadrant: "3",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "terraform",
          title: "Terraform",
          description: "Infrastructure automation to provision and manage resources in any cloud or data center.",
          key: "terraform",
          url: "https://www.terraform.io/",
          quadrant: "2",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "aws_lambda",
          title: "AWS Lambda",
          description: "AWS Lambda is a compute service that runs your code in response to events and automatically manages the compute resources, making it the fastest way to turn an idea into a modern, production, serverless applications.",
          key: "aws_lambda",
          url: "https://aws.amazon.com/lambda/?nc1=h_ls",
          quadrant: "3",
          timeline: [{ moved: 0, ringId: "trial", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "docker",
          title: "Docker",
          description: "Docker is a platform designed to help developers build, share, and run container applications.",
          key: "docker",
          url: "https://www.docker.com/",
          quadrant: "2",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "infras_as_code",
          title: "Infrastructure as Code",
          description: "Infrastructure as code (IaC) is the ability to provision and support your computing infrastructure using code instead of manual processes and settings.",
          key: "infras_as_code",
          url: "https://aws.amazon.com/de/what-is/iac/",
          quadrant: "1",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "oracle_db",
          title: "Oracle DB",
          description: "Oracle Database is a proprietary multi-model database management system produced and marketed by Oracle Corporation",
          key: "oracle_db",
          url: "https://www.oracle.com/de/database/",
          quadrant: "3",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "jruby",
          title: "Jruby",
          description: "JRuby is a high performance, stable, fully threaded Java implementation of the Ruby programming language.",
          key: "jruby",
          url: "https://www.jruby.org/",
          quadrant: "2",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "backstage_io",
          title: "backstage.io",
          description: "An open source framework for building developer portals",
          key: "backstage_io",
          url: "https://backstage.io/",
          quadrant: "3",
          timeline: [{ moved: 0, ringId: "assess", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "ddd",
          title: "Domain Driven Design",
          description: "Software design approach",
          key: "ddd",
          url: "https://de.wikipedia.org/wiki/Domain-driven_Design",
          quadrant: "1",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "dependabot",
          title: "Debendabot",
          description: "Automated dependency updates built into GitHub",
          key: "dependabot",
          url: "https://github.com/dependabot",
          quadrant: "3",
          timeline: [{ moved: 0, ringId: "adopt", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "kafka",
          title: "Kafka",
          description: "Apache Kafka is an open-source distributed event streaming platform used by thousands of companies for high-performance data pipelines, streaming analytics, data integration, and mission-critical applications.",
          key: "kafka",
          url: "https://kafka.apache.org/",
          quadrant: "3",
          timeline: [{ moved: 0, ringId: "hold", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "remix",
          title: "Remix",
          description: "Remix is a full stack web framework that lets you focus on the user interface and work back through web standards to deliver a fast, slick, and resilient user experience. People are gonna love using your stuff.",
          key: "remix",
          url: "https://remix.run/",
          quadrant: "4",
          timeline: [{ moved: 0, ringId: "trial", date: new Date('2024-09-01'), description: "NONE" }]
        },
        {
          id: "valkey",
          title: "Valkey",
          description: "Valkey is an open source, in-memory, high performance, key-value datastore. It is a drop-in replacement for Redis OSS.",
          key: "",
          url: "https://aws.amazon.com/elasticache/what-is-valkey/",
          quadrant: "3",
          timeline: [{ moved: 0, ringId: "assess", date: new Date('2024-09-01'), description: "NONE" }]
        }
      ],
      rings: [
        {
          id: "adopt",
          name: "ADOPT",
          color: "#5BA300",
          description: "Technologies we have high confidence in to serve our purpose, also in large scale. Technologies with a usage culture in our production environment, low risk and recommended to be widely used."
        },
        {
          id: "trial",
          name: "TRIAL",
          color: "#009EB0",
          description: "Technologies that we have seen work with success in project work to solve a real problem; first serious usage experience that confirm benefits and can uncover limitations. TRIAL technologies are slightly more risky; some engineers in our organization walked this path and will share knowledge and experiences."
        },
        {
          id: "assess",
          name: "ASSESS",
          color: "#C7BA00",
          description: "Technologies that are promising and have clear potential value-add for us; technologies worth to invest some research and prototyping efforts in to see if it has impact. ASSESS technologies have higher risks; they are often brand new and highly unproven in our organisation. You will find some engineers that have knowledge in the technology and promote it, you may even find teams that have started a prototyping effort."
        },
        {
          id: "hold",
          name: "HOLD",
          color: "#E09B96",
          description: "Technologies not recommended to be used for new projects. Technologies that we think are not (yet) worth to (further) invest in. HOLD technologies should not be used for new projects, but usually can be continued for existing projects."
        }
      ],
      quadrants: [
        { id: "1", name: "Techniques, Methods & Patterns" },
        { id: "2", name: "Tools" },
        { id: "3", name: "Platforms & Operations" },
        { id: "4", name: "Language & Frameworks" }
      ]
    }
  }
}